<template>
    <div class="side">
        <div v-for="item in news" :key="item.id" @click="handleView(item)">
            <span class="title">{{ item.title }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Side',
        data(){
            return {
                pagination: {
                    limit: 10,
                },
                news: [],
            }
        },
        activated(){
            this.getNews();
        },
        methods: {
            getNews() {
                // http://192.168.23.15:8000/article/home
                this.api
                    .postFormAPISM(
                        {
                            ...this.pagination,
                            keywords: "",
                            start_time: '',
                        },
                        "/article/home"
                    )
                    .then((res) => {
                        let temp = res.data.data.article_info_list;
                        this.news = temp.map(item => {
                            return {
                                title: item.title,
                                id: item.id,
                            }
                        });
                    })
                    .catch((error) => {
                        console.log("error", error);
                    });
            },
            handleView({ title, id }) {
                // this.$router.push(
                //     {
                //         path: '/news',
                //         query: {
                //             page: page + 1,
                //         }
                //     }
                // );
                let text = this.$router.resolve({
                    path: "/news",
                    query: {
                        title,
                        id,
                    },
                });
                console.log("text", text.href);
                // 打开一个新的页面
                window.open(text.href, "_blank");
            },
        }
    }
</script>
<style lang="less">
    @import './index.less';
    .side{
        padding: 20px 10px 0;
        &>div{
            border-bottom: 1px solid #eee;
            font-size: 14px;
            color: #555;
            padding: 15px 0;
            &>span{
                cursor: pointer;
            }
        }
    }
</style>