<template>
  <div id="news">
    <div class="contain">
      <div class="title">
        {{ detailInfo.title }}
      </div>
      <div class="source">
        <span class="time">{{
          detailInfo.send_time
        }}</span>
        <span class="source">
          <template>
            来源：
            {{ detailInfo.source || "--" }}
          </template>
        </span>
        &nbsp;&nbsp;
        <span class="author">
          <template>
            {{ detailInfo.author || "&nbsp;" }}
          </template>
        </span>
        <div class="collect_share">
          <!-- <img src="../../assets/images/collect.png" alt="" srcset=""> -->
          <div class="collect"></div>
          <!-- <img src="../../assets/images/wechat.png" alt="" srcset=""> -->
          <!-- <div class="wechat"></div> -->
          <el-popover placement="bottom" title="" popper-class="wechatPopover" width="162" trigger="click"
            v-model="visiblepop">
            <!-- <el-button slot="reference">hover 激活</el-button> -->
            <div class="collectClose" @click="collectClose" style="cursor: pointer"></div>
            <div slot="reference" class="wechat" @click="handleWechatClick"></div>
            <canvas class="qrcode" ref="qrcode_ref"></canvas>
            <div class="qrcode_msg" style="padding: 0 0 0 15px; transform: translateY(-10px)">
              <div style="font-size: 12px; width: 120px; height: 20px">
                用微信扫码二维码
              </div>
              <div style="font-size: 12px; width: 120px; height: 20px">
                分享至好友和朋友圈
              </div>
            </div>
          </el-popover>
          <!-- <img src="../../assets/images/weibo.png" alt="" srcset=""> -->
          <div class="weibo" @click="shareWeiBo"></div>
        </div>
      </div>
      <div class="content" v-html="detailInfo.content">
        <!-- {{ detailInfo.content }} -->
      </div>
    </div>
    <Side></Side>
  </div>
</template>
<script>
import QRCode from "qrcode";
import { ImageBasicUrl } from "../../api/api";
import { imageTemplate } from "../../utils/util";
import Side from "./components/side/index.vue";
export default {
  name: "News",
  components: {
    Side,
  },
  data() {
    return {
      detailInfo: {
        title: "",
        content: "",
        send_time: "",
        source: "",
        link: "",
      },
      visiblepop: false,
      WeChatLink: "",
    };
  },
  created() {
    this.SetLink();
    console.log("created, news", this.$route.query);
    // this.getNewsDetail()
  },
  activated() {
    this.getTags();
    console.log("created, news", this.$route.query);
    this.detailInfo = {
      ...this.$options.data().detailInfo,
    };
    this.getNewsDetail();
  },
  methods: {
    getTags() {
      if (!this.$route.query.id) return;
      setTimeout(async () => {
        let res = await this.api.postFormAPISM(
          {
            article_id: Number(this.$route.query.id),
          },
          '/article/settag'
        )
      }, 15000)

    },
    // 设置微信分享链接的网址
    SetLink() {
      // console.log(window.location.href,'1');
      let number = window.location.href.split("?")[1];
      this.WeChatLink = `http://cooask.com/coo/news?${number}`;
      //  console.log(this.WeChatLink);
    },
    // 关闭微信分享弹窗
    collectClose() {
      console.log(1);
      this.visiblepop = !this.visiblepop;
    },
    // 分享微博
    shareWeiBo() {
      window.open(
        "https://service.weibo.com/share/share.php?url=" +
        encodeURIComponent(window.location.href) +
        "&title=" +
        this.detailInfo.title
      );
    },
    getNewsDetail() {
      // http://192.168.23.15:8000/article/home
      // http://192.168.23.15:8000/article/getarticleinfo
      if (!this.$route.query.id) return;
      this.api
        .postFormAPISM(
          {
            // page: this.$route.query.page,
            // limit: 1,
            article_id: this.$route.query.id,
          },
          // '/article/home',
          "/article/getarticleinfo"
        )
        .then((res) => {
          console.log("getNewsDetail", res.data);
          let temp = res.data.data.article_info;
          temp.content = this.replaceImage(temp.content);
          this.detailInfo = temp;
          document.title = this.detailInfo.title;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    replaceImage(content) {
      let reg =
        /({{[^}}]+.(bmp|jpg|png|tif|gif|pcx|tga|exif|fpx|svg|psd|cdr|pcd|dxf|ufo|eps|ai|raw|WMF|webp|avif|apng)}})/g;
      let reg2 = /^{{([^}}]+)}}$/;
      let reg3 = /<br>[\s\r\n]*<br>[\s\r\n]*<br>/g; //替换三个或更多br为一个
      let temp = content.replace(reg3, "<br><br>").replace(/^<br>/, "");
      // let temp2 = content.replace(/\r/g, '###r').replace(/\n/g, '###n').replace(/\s/g, '###s');
      // console.log('temp', temp2);
      let tempImage = temp.match(reg);
      // // console.log('replaceImage', tempImage);
      if (!tempImage) return temp;
      for (let i = 0; i < tempImage.length; i++) {
        let url = reg2.exec(tempImage[i]);
        if (url instanceof Array) {
          url = url[1];
          temp = temp.replace(tempImage[i], imageTemplate(ImageBasicUrl + url));
        }
      }
      return temp;
    },
    handleClickTime() {
      let a = document.createElement("a");
      a.target = "_blank";
      a.href = this.detailInfo.link;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    handleWechatClick() {
      // console.log('handleWechatClick', this.$refs.qrcode_ref, window.location.href);
      if (!this.$refs.qrcode_ref) {
        setTimeout(() => {
          this.handleWechatClick();
        }, 300);
      } else {
        this.generateQrcode();
      }
    },
    generateQrcode() {
      console.log("generateQrcode");

      QRCode.toCanvas(this.$refs.qrcode_ref, this.WeChatLink, function (error) {
        if (error) {
          return console.error(error);
        }
        console.log("success!");
      });
    },
  },
};
</script>
<style lang="less">
@import "./index.less";
</style>
